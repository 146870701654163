
<template>
  <div></div>
</template>
  <script>
export default {

  mounted() {
    window.ChatBotAI=window.ChatBotAI||{},ChatBotAI.init=function(t){return new Promise((function(e,n){var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://fortytwogame.com/chat.js";const r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r),o.addEventListener("load",(function(){let o;window.ChatBotAI.mount({id:t.id,options:t.options}),o=function(t){return new Promise((function(e){if(document.querySelector(t))return e(document.querySelector(t));const n=new MutationObserver((function(o){document.querySelector(t)&&(e(document.querySelector(t)),n.disconnect())}));n.observe(document.body,{childList:!0,subtree:!0})}))},o&&o("#chatbotai-root").then(e).catch(n)})),o.addEventListener("error",(function(t){n(t.message)}))}))};
    ChatBotAI.init({
      id: "bafb681f-90a5-4b37-b916-c586a3536c98",
      apiBaseURL: "https://fortytwogame.com/api/chatbot",
      options: {
        alignment: "right",
        horizontalMargin: 0,
        verticalMargin: 0,
        showButtonLabel: false,
      },
    }).then(() => {
      // Script is safely loaded, you can do whatever you want from here with bot
    });
  },
};
</script>